import { styled } from '@compiled/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { ChangeEditionSectionMessage } from '../SectionMessage/ChangeEditionSectionMessage';
import type { ChangeEditionSectionMessageProps } from '../SectionMessage/ChangeEditionSectionMessage';

export type CardProps = PropsWithChildren<ChangeEditionSectionMessageProps>;

// https://hello.atlassian.net/wiki/spaces/CE2/pages/2804105628/Feature+gates+with+Taste+Tone+styling#Upsell-variants
export const Card = ({ children, ...props }: CardProps) => (
	<CardLayout>
		{React.Children.count(children) < 2 ? children : <div>{children}</div>}
		<CardSectionMessageContainer>
			<ChangeEditionSectionMessage
				{...props}
				withTasteAndToneStyling
				buttonGroupAlignment="right"
			/>
		</CardSectionMessageContainer>
	</CardLayout>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardLayout = styled.div({
	display: 'flex',
	flexDirection: 'column',
	flexGrow: 1,
	alignItems: 'center',
	backgroundColor: `${token('elevation.surface.overlay', N0)}`,
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardSectionMessageContainer = styled.div({
	padding: token('space.300', '24px'),
	alignSelf: 'stretch',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardContentsContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div': {
		whiteSpace: 'initial',
	},
});
CardContentsContainer.displayName = 'CardContentsContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWithBorderAndBox = styled.div({
	borderRadius: token('space.050', '5px'),
	border: `1px solid var(--light-editions-border-gradient, #579DFF)`,
	background: token('elevation.surface.overlay', N0),
	boxShadow: `${token(
		'elevation.shadow.overlay',
		'0px 8px 12px rgba(9, 30, 66, 0.15),0px 0px 1px rgba(9, 30, 66, 0.31)',
	)}`,
	overflow: 'hidden',
});

export const CardContainer = ({ children }: React.PropsWithChildren<{}>) => (
	<CardWithBorderAndBox>
		<CardContentsContainer>{children}</CardContentsContainer>
	</CardWithBorderAndBox>
);
